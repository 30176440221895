import React, { Component } from "react";
import {ReactBootstrap, Panel, Row, Col} from 'react-bootstrap';
import Heading from './subComponents/Heading';

class Contact extends React.Component{
    render(){
        const pageStyle = {
            padding: '20px'
        }
        return (
            <div style={pageStyle}>
                <Heading name="Find Me" />
                <Panel>
                    <Panel.Heading>
                        <h3>Find Me</h3>
                    </Panel.Heading>
                    <Panel.Body>
                        <Row>
                            <Col sm={6}>
                                <div className="AddressWrapper">
                                    <ul>
                                        <li>
                                            <i class="fas fa-map-marker-alt"></i>
                                            C - 155, Dayanand Marg <br />
                                            Tilak Nagar,<br />
                                            Jaipur, India 302004.
                                        </li>
                                        <li>
                                            <i class="fas fa-envelope-open-text"></i>
                                            <a href="mailto:ashutoshbhatia@gmail.com">
                                                ashutoshbhatia@gmail.com
                                            </a>
                                        </li>
                                        <li>
                                            <i class="fas fa-globe"></i>
                                            <a href="http://aleaitsolutions.com" target="_blank">
                                                aleaitsolutions.com
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.4496601741043!2d75.82332041504439!3d26.88922088313771!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db134ad083d1d%3A0x6d10dcd86996dfd5!2sALEA+IT+SOLUTIONS!5e0!3m2!1sen!2sin!4v1547027924038" 
                                    width="100%" 
                                    height="350" 
                                    frameborder="0"
                                    allowfullscreen>
                                </iframe>
                            </Col>
                        </Row>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}

export default Contact;