import React, { Component } from "react";
import {ReactBootstrap, Panel, Row, Col} from 'react-bootstrap';
import Heading from './subComponents/Heading';

class Blog extends React.Component{
    render(){
        const pageStyle = {
            padding: '20px'
        }
        return (
            <div style={pageStyle}>
            <Heading name="Blogs" />
                <Panel>
                    <Panel.Heading>
                        <h3>Blogs</h3>
                    </Panel.Heading>
                    <Panel.Body>
                        <h1>Coming Soon...</h1>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}

export default Blog;