import React, { Component } from 'react';
import mainImg from "../images/main.jpg";
import logoName from "../images/logoname.png";

class sidebar extends Component {
  render() {
   
    const logo = {
      textAlign: 'center',
      overflow: 'hidden',
      width: '150px',
      height: '150px',
      borderRadius: '50%',
      margin: '30px auto'
    }
    const logoImg = {
      transform: 'rotate(-28deg)',
      margin: 'auto',
      display: 'block'
    }
    const owerName = {
      letterSpacing: '1px',
      fontSize: '25px',
      marginBottom: '0px'
    }
    const logoname={
      maxWidth: '100%',
    }
    const ownerSpeciality = {
      fontSize: '12px',
      color: '#F9A825',
      marginTop: '0px'
    }
    return (
      <div>
        <div className="main_image" style={logo}>
          <img src={mainImg} style={logoImg}  height="250px" />
        </div>
        <div className="owner_intro">
          <h3 style={owerName}>
            <img src={logoName} style={logoname} />
          </h3>
          <p style={ownerSpeciality}>Author | Motivational Guru | IT consultant | Photographer</p>
        </div>
      </div>
    );
  }
}

export default sidebar;