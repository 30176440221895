import React, { Component } from "react";
import {ReactBootstrap, Panel, Row, Col, Alert} from 'react-bootstrap';
import Heading from './subComponents/Heading';
class Quotes extends React.Component{
    render(){
        const pageStyle = {
            padding: '20px'
        }
        return (
            <div style={pageStyle}>
                <Heading name="Quotes" />
                <Panel>
                    <Panel.Heading>
                        <h3>Quotes</h3>
                    </Panel.Heading>
                    <Panel.Body>
                        <Row>
                            <Col sm={8} smOffset={2}>
                                <div className="quote-card yellow-card">
                                    <p>
                                         "Seldom is one aware what destiny holds in its closed palm. Plan your path to be the best of your 
                                         ability but fate will be revealed once the hands of destiny unfold."
                                    </p>
                                </div>
                                <div className="quote-card yellow-card">
                                    <p>
                                        "Dreams you see are the steps of the ladder to your life. If you have not built any steps for 
                                        yourself, you have no option but to choose from what you get on the road of life."
                                    </p>
                                </div>
                                <div className="quote-card yellow-card">
                                    <p>
                                        "The ladder of success is built one step at a time, but you cannot fight destiny and place all the 
                                        steps yourself, some steps you need to build yourself while others are placed already for you."
                                    </p>
                                </div>
                                <div className="quote-card yellow-card">
                                    <p>
                                        "Bumps along the road of success are signboards which convey that your destination if closer now."
                                    </p>
                                </div>
                                <div className="quote-card yellow-card">
                                    <p>
                                        "Try and distinguish between self-will and hope. Both seem intertwined yet without your 'Will' no 'hope' can 
                                        live for long."
                                    </p>
                                </div>
                                <div className="quote-card yellow-card">
                                    <p>
                                        "Success achieved is just a stopover on the journey of life. No sooner the dust settles, you will 
                                        have not option but to leave the temporary shelter and move along the road."
                                    </p>
                                </div>
                                <div className="quote-card yellow-card">
                                    <p>
                                        "Somewhere some day you get a chance. The chance tests you and if you prove your worth, 
                                        you succeed, the first success is just a stepping stone. Keep travelling on this road with a 
                                        positive mind, work with loyalty and keep moving towards the goal. One day no destination that 
                                        you choose will be beyond your reach."
                                    </p>
                                </div>
                                <div className="quote-card yellow-card">
                                    <p>
                                        "Happiness is not when your dreams come true, it is when your dreams coincide with the happiness 
                                        of the people around you."
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <Alert bsStyle="danger" className="text-center">
                            <strong>Quotes are copyrighted by Ashutosh Bhatia(©) SMTP - Crorepati Turns Roadpati</strong>
                        </Alert>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}

export default Quotes;