import React, { Component } from "react";
import {ReactBootstrap, Grid, Panel, Row, Col, Table} from 'react-bootstrap';
import Heading from './subComponents/Heading';
import SMTP from '../images/final_cover.jpg';
import SecondBook from '../images/second_book.jpg';

class Books extends React.Component{
    render(){
        const pageStyle = {
            padding: '20px'
        }
        const imgStyle ={
            maxWidth: '100%'
        }
        return (
            <div style={pageStyle}>
                <Heading name="Books" />
                <Panel>
                    <Panel.Heading>
                        <h3>1. Croprepati Turns Roadpati.</h3>
                    </Panel.Heading>
                    <Panel.Body>
                        <Row className="show-grid">
                            <Col sm={3}>
                                <img src={SMTP} style={imgStyle} />
                            </Col>
                            <Col sm={9}>
                                <p>
                                    Aaryan, a simpleton boy from a town, dreams big and is always too eager so as to let the life unfold 
                                    at his own pace. He is 'flamboyant' in nature and is always surrounded by the thoughts of one or the 
                                    other girl. It is said "The moment you have in your heart this extraordinary thing called love and 
                                    feel the depth, the delight, the ecstasy of it, you will discover that for you the world is transformed". 
                                    So did his, when he meets "Shona".
                                </p>
                                <p>
                                    He starts climbing the ladder of success and reaches to the optimum level. But life has decided 
                                    something else for him, the day he becomes a crorepati, in real sense turns a Roadpati, as he 
                                    loses his biggest bundle of joy. Destiny takes him on a journey, filled with many crossroads and 
                                    Aaryan is forced to choose a turn to continue it. 'These are the turning points of his life'.
                                </p>
                                <p>
                                    It is not that the choices were always made by him, sometimes fate made the choices. He always 
                                    wanted to be a successful man with loads of money but, this lil boy turned businessman gets into so 
                                    many troubles which break him into pieces. But life maintains the balance something like yin & yen 
                                    where all up turns filled with triumphs are followed by horror filled down turns forcing Aaryan to be 
                                    a Roadpati.
                                </p>
                                <Row>
                                    <Col sm={6}>
                                        <Table bordered responsive condensed hover>
                                            <tr>
                                                <td>
                                                    <strong>Publisher :</strong>
                                                </td>
                                                <td>
                                                    Mahaveer Publishers
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>ISBN(10) :</strong>
                                                </td>
                                                <td>
                                                    9350880296
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>ISBN(13) :</strong>
                                                </td>
                                                <td>
                                                    9789350880296
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Pages :</strong>
                                                </td>
                                                <td>
                                                    192
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Binding :</strong>
                                                </td>
                                                <td>
                                                    Paperback
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Category :</strong>
                                                </td>
                                                <td>
                                                    Fiction
                                                </td>
                                            </tr>
                                        </Table>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Panel.Body>
                </Panel>
                <Panel>
                    <Panel.Heading>
                        <h3>2. B.</h3>
                    </Panel.Heading>
                    <Panel.Body>
                        <Row className="show-grid">
                            <Col sm={3}>
                                <img src={SecondBook} style={imgStyle} />
                            </Col>
                            <Col sm={9}>
                                <h1>Commig Soon</h1>
                            </Col>
                        </Row>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}

export default Books;