import React, { Component } from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ReactBootstrap from 'react-bootstrap';
import {Nav, NavItem} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Blog from './components/Blog';
import Home from './components/Home';
import Biography from './components/Biography';
import Books from './components/Books';
import Reviews from './components/Reviews';
import Gallery from './components/Gallery';
import Quotes from './components/Quotes';
import Contact from './components/Contact';
import Social from './components/SocialFooter';

class App extends Component {
  render() {
     const sibebarStyle = {
       background: '#232323',
       width: '300px',
       position: 'fixed',
       left: '0px',
       top: '0px',
       height: '100%',
       padding: '15px',
       color: '#ffffff',
       textAlign: 'center',
     }
     const MainContentContainer = {
       padding: '0px 20px 20px 300px',
       color: '#000'
     }
     
    return (
      <BrowserRouter>
        <div className="Main_wrapper">
          <div className="Sidebar" style={sibebarStyle}>
            <Sidebar/>
            <div className="subnav_bar">
            <div className="nav">
              <NavLink to="/" >Home</NavLink>
              <NavLink to="/biography">Biography</NavLink>
              <NavLink to="/books">Books</NavLink>
              <NavLink to="/reviews">Reviews</NavLink>
              <NavLink to="/gallery">Gallery</NavLink>
              <NavLink to="/quotes">Quotes</NavLink>
              <NavLink to="/blog">Blog</NavLink>
              <NavLink to="/contact">Contact</NavLink>
            </div>
            <Social />
          </div>
          </div>
          <div className="Main_container" style={MainContentContainer}>
            <Route path="/" exact component={Home} />
            <Route path="/biography" component={Biography} />
            <Route path="/books" component={Books} />
            <Route path="/reviews" component={Reviews} />
            <Route path="/gallery" component={Gallery} />
            <Route path="/quotes" component={Quotes} />
            <Route path="/blog" component={Blog} />
            <Route path="/contact" component={Contact} />
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
