import React, { Component } from "react";
import {ReactBootstrap, Carousel} from 'react-bootstrap';
import sliderImg1 from '../images/slider.jpg';
import sliderImg2 from '../images/slider2.jpg';
import sliderImg3 from '../images/slider3.jpg';

class Home extends React.Component{
    render(){
        const main_page = {
            margin: '0px -20px -20px 00px'
        }
        const sliderImgStyle = {
            width: '100%',
            height: '100vh'
        }
        return(
            <div style={main_page}>
                <Carousel className="carousel-fade">
                    <Carousel.Item>
                        <img src={sliderImg1} style={sliderImgStyle} />
                    </Carousel.Item>
                    < Carousel.Item>
                        <img src={sliderImg2} style={sliderImgStyle} />
                    </Carousel.Item>
                    < Carousel.Item>
                        <img src={sliderImg3} style={sliderImgStyle} />
                    </Carousel.Item>
                </Carousel>
            </div>
        );
    }
}

export default Home;