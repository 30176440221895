import React, { Component } from "react";
import {ReactBootstrap,Panel, Row, Col} from 'react-bootstrap';
import Heading from './subComponents/Heading';
import IMG1 from '../images/01.jpg'
import IMG2 from '../images/02.jpg'
import IMG3 from '../images/03.jpg'
import IMG4 from '../images/04.jpg'
import IMG5 from '../images/05.png'
import IMG6 from '../images/06.jpg'
import IMG7 from '../images/07.jpg'

class Reviews extends React.Component{
    render(){
        const pageStyle = {
            padding: '20px'
        }
        return (
            <div style={pageStyle}>
                <Heading name="Reviews" />
                <Panel>
                    <Panel.Heading>
                        <h3>Reviews</h3>
                    </Panel.Heading>
                    <Panel.Body>
                        <div className="ReviewContainer">
                            <Row>
                                <Col sm={8} smOffset={2}>
                                    <div className="reviewWrapper">
                                        <div className="ClientPicture">
                                            <img src={IMG1} />
                                        </div>
                                        <div className="reviewContent">
                                            <p>
                                                To describe the book 'So Many Turning Points - Crorepati Turns Roadpati', there are three simple words - Honest, Intriguing and Inspirational.
                                                Being his first book, Ashutosh Bhatia has chosen simple narrative written in colloquial style - something that naturally attracts readers.

                                                Portions of the book may also remind you of childhood favourite - "Malgudi Days!"

                                                Aryan, who plays the lead protagonist, reminds you of your own self at lots of instances.His impulsive nature and instant decisions that land him in trouble and then his sheer luck and honesty that pulls him through thick and thin makes it a heart - rending and curious read.Every turning point in Aryan 's life will make you think: "What next? Will Aryan land in trouble again?"

                                                For a debut writer aiming to make it big, Ashutosh Bhatia has done a fairly neat job.
                                            </p>
                                        </div>
                                        <div className="ClientNameInfo">
                                            <p>
                                                <strong>
                                                    <i>Sidhi Chadha Kapoor, Assistant Editor @ India Today Group</i>
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="ReviewContainer">
                            <Row>
                                <Col sm={8} smOffset={2}>
                                    <div className="reviewWrapper">
                                        <div className="ClientPicture">
                                            <img src={IMG2} />
                                        </div>
                                        <div className="reviewContent">
                                            <p>
                                                "Excellent narration, a story that is so true for lot of strugglers in 1990s and even today."
                                            </p>
                                        </div>
                                        <div className="ClientNameInfo">
                                            <p>
                                                <strong>
                                                    <i>Mukesh Gupta, Director, M.R.Morarka-GDC Rural Research Foundation</i>
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="ReviewContainer">
                            <Row>
                                <Col sm={8} smOffset={2}>
                                    <div className="reviewWrapper">
                                        <div className="ClientPicture">
                                            <img src={IMG3} />
                                        </div>
                                        <div className="reviewContent">
                                            <p>
                                                Dear Ashutosh Bhatia, Many Congrats on this very inspiring story and yet heart wrenching!!

                                                    It is Inspiring yet very real and i Hope and wish everybody reads this and learns a lesson from this story!!

                                                    SMTP will surely create a stir.
                                            </p>
                                        </div>
                                        <div className="ClientNameInfo">
                                            <p>
                                                <strong>
                                                    <i>Shibani Kashyap, Famous Bollywood Singer & Lyrist</i>
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="ReviewContainer">
                            <Row>
                                <Col sm={8} smOffset={2}>
                                    <div className="reviewWrapper">
                                        <div className="ClientPicture">
                                            <img src={IMG4} />
                                        </div>
                                        <div className="reviewContent">
                                            <p>
                                                "Inspiring, relatable tale of a typical small town boy who dreams to make it big."
                                            </p>
                                        </div>
                                        <div className="ClientNameInfo">
                                            <p>
                                                <strong>
                                                    <i>Manik Jaiswal / Narendar Singh, The Bookoholics</i>
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="ReviewContainer">
                            <Row>
                                <Col sm={8} smOffset={2}>
                                    <div className="reviewWrapper">
                                        <div className="ClientPicture">
                                            <img src={IMG5} />
                                        </div>
                                        <div className="reviewContent">
                                            <p>
                                               Monsoon is here and this could be the only novel in market without any F-word to adulterate your communication. Go for it.
                                            </p>
                                        </div>
                                        <div className="ClientNameInfo">
                                            <p>
                                                <strong>
                                                    <i>Paritosh Louhan, Author at The India Fusion</i>
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="ReviewContainer">
                            <Row>
                                <Col sm={8} smOffset={2}>
                                    <div className="reviewWrapper">
                                        <div className="ClientPicture">
                                            <img src={IMG6} />
                                        </div>
                                        <div className="reviewContent">
                                            <p>
                                               Though the book successfully reflects the dilemma every student faces
                                               while making career choices, but the choices made are quite confusing yet shown to fare results that are exceptionally good.
                                            </p>
                                        </div>
                                        <div className="ClientNameInfo">
                                            <p>
                                                <strong>
                                                    <i>The Era I lived in</i>
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="ReviewContainer">
                            <Row>
                                <Col sm={8} smOffset={2}>
                                    <div className="reviewWrapper">
                                        <div className="ClientPicture">
                                            <img src={IMG7} />
                                        </div>
                                        <div className="reviewContent">
                                            <p>
                                               So many turning points Crorepati turn Roadpati. Is an awesome book a must read! Simply written 
                                               the story as such has been amazingly spun. This web has all that it needs to entertain, teach, 
                                               and make you cry. The characters are woven in a way that you can instantly connect with them. 
                                               At times i can relate myself with Aaryan he seems to be a lilt' impatient and so do I ;)
                                            </p>
                                        </div>
                                        <div className="ClientNameInfo">
                                            <p>
                                                <strong>
                                                    <i>Anshuma Sharma, Blogger</i>
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}

export default Reviews;