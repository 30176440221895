import React, { Component } from 'react';

class Heading extends React.Component{
    render(){
        return(
            <div>
                <h2 className="main_page_heading">{this.props.name}</h2>
                <span className="small_bottom"></span>
            </div>
        );
    }
}


export default Heading;