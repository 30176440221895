import React, { Component } from "react";
import {ReactBootstrap, Panel, Row, Col} from 'react-bootstrap';
import Heading from './subComponents/Heading';
import Ash from '../images/ash.JPG';

class Biography extends React.Component{
    render(){
        const pageStyle = {
            padding: '20px'
        }
        const AshStyle = {
            width: '100%'
        }
        const TopBlockStyle ={
            paddingTop: '50px',
            fontSize: '16px'

        }
        return (
            <div style={pageStyle}>
                <Heading name="Biography" />
                <Panel>
                    <Panel.Heading>
                        <h3>About 'Ashutosh Bhatia'</h3>
                    </Panel.Heading>
                    <Panel.Body>
                        <Row>
                            <Col sm={3}>
                                <img src={Ash} className="AshStyle" style={AshStyle} />
                            </Col>
                            <Col sm={9}>
                                <div style={TopBlockStyle}>
                                    <p><strong>Ashutosh Bhatia</strong> is an established IT entrepreneur hailing from the Pink City Jaipur. 
                                    An avid reader from his early years, Ashutosh has been contributing to various periodicals and published 
                                    during schooling at St. Xaviers, Jaipur.</p>
                                    <p>
                                        Ashutosh is a multitalented person, who along with his exploits in the IT Sector had been 
                                        involved in many extra-curricular activities throughout his school & College. After completing his 
                                        Master’s in Computers, he started his venture in IT and has since ventured into other businesses as well. 
                                        He is a great story teller and would take you on an impressive ride through the life of his characters.
                                    </p>
                                    <p>
                                        Some real happenings around Ashutosh inspired him to take up writing fiction and thus his maiden 
                                        attempt at that "SMTP - Crorepati Turns Roadpati."
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Panel.Body>
                    <Panel.Heading>
                        <h3>Motivational Guru</h3>
                    </Panel.Heading>
                    <Panel.Body>
                        <p>
                            <strong>
                                "Believe in yourself, have faith in your abilities! Without a humble but a reasonable confidence in 
                                your powers you cannot be successful or happy "
                            </strong>
                        </p>
                        <p>
                            Norman Vincent Peale, Author of 'The Power of Positive Thinking'
                        </p>
                        <p>
                            Ashutosh Bhatia has immersed himself in every field at his best. Being an IT consultant is in itself a tough 
                            task requiring all your time but he leaves no stone unturned to motivating young minds. He has been working 
                            as a Motivational Guru since quite some time now and has been successful in transfusing positive vibes and dynamicity 
                            in his audience. He believes in the power of positive thinking and recommends this book to every person who may or may 
                            not be going through rough waters. According to him, positive thinking can work wonders in your career graph as 
                            well as in your personal life. He has worked with all kinds of clients, professionals, students, media person and 
                            what not. His clients have always been content with his speeches and are pleased to be infused with a new found confidence. 
                            He is a dynamic and experienced speaker and has researched a lot in this field. He even plans to write a book on motivational 
                            speaking and soft skills very soon. His debut novel which is fiction is expected to release next year. He blends humour and
                             his motivational talks in order to bring enthusiasm in his audience. His audience have termed him as one of the best 
                             motivational speakers in Delhi NCR because of highly inspiring and motivating content that brings positive thinking power.
                        </p>
                        <p>
                            He helps people get out of pressure being faced in work places as well as personal life, he gives the key to 
                            getting successful in life with proper time management and skills. He firmly agrees to what Ayn Rand, the author 
                            of the very popular book 'The Fountainhead' and 'Atlas Shrugged'. He mainly specializes in stress management, 
                            time management, leadership qualities, improving self confidence and human relations. His audience have confirmed 
                            that his motivational speaking have resulted in better productivity at work and employee-employer relationships. 
                            He also helps people work on their selling skills that include prospecting, analysis, closing, Customer Care, etc. 
                            He gives out best ways to achieve success in business and how to handle the ups and downs in corporate world. 
                            He conducts Seminars and Workshops to help people develop themselves, to extract their best, all in all to 
                            unleash the power within to make miracles happen. He is a mind trainer and firmly states that a peaceful and 
                            content mind works the best and therefore meditation is very important to do wonders at work level and 
                            maintain a healthy and joyous family life. He is an Expert Motivational Guru who has clients consisting of 
                            top notch businessmen, employees, students, housewives, a lot of people working in the corporate world and 
                            everyone who wishes for a content and successful life.
                        </p>
                        <p>
                            Ashutosh Bhatia had been drawn towards the pen since the beginning but it was mainly related to work and personal issues. 
                            And then came a time he took step into creative writing and came up with the book "So many turning points... 
                            A crorepati turned 'roadpati'". His debut novel will be hitting the market soon and is already making waves 
                            among the readers. In this book, he has talked about his personal experiences that have been significant in shaping 
                            his career. The title is quite intriguing and according to Ashutosh, he crossed out on it because he felt every 
                            person would be able to relate to it.
                        </p>
                    </Panel.Body>
                    <Panel.Heading>
                        <h3>IT Consultant</h3>
                    </Panel.Heading>
                    <Panel.Body>
                            <p>
                                IT Consultancy is something Ashutosh Bhatia has been connected with since the beginning. He began working 
                                on it during the time when computer usage was least in India and people didn't know about the actual 
                                benefits of the web world in the best possible manner. And since then he has been progressing in the 
                                field setting up a benchmark for himself. He is among the leading IT Consultants of the nation with an 
                                experience of almost eight years to his credit.
                            </p>
                    </Panel.Body>
                    <Panel.Heading>
                        <h3>Photographer</h3>
                    </Panel.Heading>
                    <Panel.Body>
                            <p>
                                IT Consultant by profession, motivational speaker by soul, writer by chance and a photographer at heart, 
                                this is what defines a multi talented person like Ashutosh Bhatia. A photograph is life preserved for 
                                eternity, time may pass by but memories stay and what better to keep these memories forever than clicking 
                                them safe in a camera. Photographs are what adore our house, diaries, working table and our hearts. 
                                And Ashutosh Bhatia believes in keeping all sweet and sour, relevant and irrelevant moments of success or 
                                failure in the form of snaps. His lens will capture even a minor scene which may easily get unnoticed. 
                                His keen observance and the power to speak through visual medium does miracles to every moment that comes 
                                under the lens of his camera. He possesses the magic bring life into any object he attempts to capture. 
                                Photographers and writers are said to be closest to nature and Ashutosh dons the hat of both of them and 
                                has a sharp eye for every object present on this planet.
                            </p>
                            <p>
                                Ashutosh drives inspiration from Nicephore Niepce, the man who introduced photography and William Henry 
                                Fox Talbot, the father of photography while clicking pictures. He hasn’t taken a formal training in 
                                photography but has always been interested in studying literature related to this field. No wonder he 
                                is found reading a lot of journals and books on photography in his free time. According to Ashutosh, 
                                magnificence isn’t what he looks for taking a picture, the purity in true form is what attracts him towards 
                                clicking pictures of objects. From nature to food to the festive celebration, his lens will bring out 
                                the beauty in its own way. Abstract photography to wedding photography, he never leaves his home 
                                without his camera. From simple hones expressions to made up expressions, human emotions, a cup of 
                                coffee or tea in a mud pot, diversity in nature to the vast culture and heritage of our nation, 
                                nothing seems to escape his camera’s eyes. The colours of life is what Ashutosh desires to bring 
                                out from his photography skills and most of the time he is able to achieve it. Even though he loves 
                                to take picture of almost every single thing that crosses his way, he personally finds nature 
                                photography closest to him.
                            </p>
                            <p>
                                His love for photography takes him from villages to across the boundaries. Packed up in woollens and 
                                boots with a camera hanging on his neck, Ashutosh often goes out on expeditions on his own to the snow 
                                covered mountains in search of answers to life, self introspection and a thirst for that special picture 
                                which would make his journey worthwhile. When asked about his favourite Indian photographer, his face 
                                instantly lights up to say, "It has to be none other than Late N. Thiagarajan(1933-2008). He created a 
                                new world through his pictures.” You’ll always find him with his camera to catch hold of anything that 
                                attracts his attention. So in case you find someone holding a camera and roaming about in the streets 
                                early in the morning, you know who it should be.
                            </p>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}

export default Biography;