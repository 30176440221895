import React, { Component } from 'react';

class Social extends React.Component{
    render(){
        return(
            <div className="social_footer">
                <ul>
                    <li>
                        <a href="https://www.facebook.com/aleait" target="_blank">
                            <i className="fab fa-facebook"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/ashalea" target="_blank">
                            <i className="fab fa-twitter"></i>
                        </a>
                    </li>
                    <li>
                        <a href="http://www.linkedin.com/in/ashutoshbhatia" target="_blank">
                            <i className="fab fa-linkedin"></i>
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}
export default Social;